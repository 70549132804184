import React from 'react';
import styles from './Links.module.css';

const Links = () => {
  return (
    <div className={`${styles.containerMain} animeLeft`}>
      <h3>Links úteis</h3>
      <a
        href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Konsist+-+POLÍTICAS+DE+PRIVACIDADE+E+SEGURANÇA.pdf"
        target="blank"
        className={styles.links}
      >
        Política de privacidade - Módulo Atendimento
      </a>
      <a
        href="https://konsist.com.br/politica-de-privacidade-whatsapp"
        target="blank"
        className={styles.links}
      >
        Política de privacidade - Whatsapp
      </a>
    </div>
  );
};

export default Links;
