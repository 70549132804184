import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Produtos from './components/Produtos/Produtos';
import Contato from './components/Contato/Contato';
import Sobre from './components/Sobre/Sobre';
import Orcamento from './components/Orcamento/Orcamento';
import Whatsapp from './components/Helper/Whatsapp';
import ScrollToTop from './components/Helper/ScrollToTop';
import ProdutoPage from './components/Produtos/ProdutoPage';
import Clientes from './components/Home/Clientes';
import Suporte from './components/Suporte/Suporte';
import Aplicativos from './components/Aplicativos/Aplicativos';
import NotFound from './components/NotFound';
import TodosProdutos from './components/Produtos/TodosProdutos';
import Pesquisa from './components/Pesquisa/Pesquisa';
import ReactGA from 'react-ga';
import { AvisoProvider } from './UserContext';
import AlterarAviso from './components/Aviso/AlterarAviso';
import Certificado from './components/Certificado/Certificado';
import ServicosOnline from './components/ServicosOnline/ServicosOnline';
import LoginCliente from './components/LoginCliente/LoginCliente';
import AreaCliente from './components/AreaCliente/AreaCliente';
import UserContext, { UserStorage } from './UserContextLogin';
import ProtectedRoute from './components/Helper/ProtectedRoute';
import PoliticaWhatsApp from './components/PoliticaDePrivavidadeWhats/PoliticaWhatsApp';

ReactGA.initialize('G-JZHTKB7RQT');

function App() {
  // const location = useLocation()

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <div className="app">
      <BrowserRouter>
        <AvisoProvider>
          <UserStorage>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/pesquisa" element={<Pesquisa />} />
              <Route path="/produtos/*" element={<TodosProdutos />} />
              <Route path="/contato" element={<Contato />} />
              <Route path="/sobre" element={<Sobre />} />
              <Route path="/orcamento" element={<Orcamento />} />
              {/* <Route path="/produtos/*" element={<ProdutoPage />} /> */}
              <Route path="/clientes" element={<Clientes />} />
              <Route path="/suporte" element={<Suporte />} />
              <Route path="/aplicativos/*" element={<Aplicativos />} />
              <Route path="/alteraraviso/*" element={<AlterarAviso />} />
              <Route path="/certificado" element={<Certificado />} />
              <Route path="/servicosonline" element={<ServicosOnline />} />
              <Route
                path="/politica-de-privacidade-whatsapp"
                element={<PoliticaWhatsApp />}
              />
              <Route path="*" element={<NotFound />} />
              <Route path="/login/*" element={<LoginCliente />} />
              <Route
                path="/area-cliente/*"
                element={
                  <ProtectedRoute>
                    <AreaCliente />
                  </ProtectedRoute>
                }
              />
            </Routes>
            <Footer />
            <Whatsapp />
          </UserStorage>
        </AvisoProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
