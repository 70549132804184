import React from 'react';
import styles from './PoliticaWhatsApp.module.css';

const PoliticaWhatsApp = () => {
  return (
    <div className={styles.MainContainer}>
      <h1 className={styles.titulo}>Política de Privacidade WhatsApp</h1>
      <div className={styles.subtitle}>
        {/* <h2>Política de Privacidade</h2> */}
        <p className={styles.atualizacao}>
          Última atualização: 28 de janeiro de 2025
        </p>
      </div>
      <div className={styles.item}>
        <h2 className={styles.h2SubTitle}>Coleta e Uso de Dados</h2> Nosso
        aplicativo utiliza exclusivamente os números de telefone e nomes
        fornecidos pelos usuários ou por terceiros autorizados para o envio de
        mensagens. Não coletamos, armazenamos ou processamos outras informações
        pessoais além dos números de telefone e nomes necessários para a entrega
        das mensagens.
      </div>{' '}
      <div className={styles.item}>
        <h2 className={styles.h2SubTitle}>Finalidade do Uso dos Dados</h2>{' '}
        <span className={styles.DoisPontos}>
          Os números de telefone e os nomes são utilizados apenas para os
          seguintes fins:
        </span>{' '}
        Envio de mensagens via API nuvem do WhatsApp Business. Os dados
        fornecidos não serão compartilhados, vendidos ou usados para outras
        finalidades.
      </div>
      <div className={styles.item}>
        <h2 className={styles.h2SubTitle}>
          Armazenamento de Dados Armazenamos
        </h2>{' '}
        <span className={styles.DoisPontos}>Números de telefone e nomes:</span>{' '}
        Os números e nomes são armazenados em nossos sistemas de forma segura
        para possibilitar o envio das mensagens e garantir a rastreabilidade das
        comunicações.
      </div>{' '}
      <div className={styles.item}>
        <h2 className={styles.h2SubTitle}>Segurança dos Dados</h2> Utilizamos
        medidas técnicas para garantir que os dados processados sejam protegidos
        contra acessos não autorizados durante o uso da plataforma.
      </div>{' '}
      <div className={styles.item}>
        <h2 className={styles.h2SubTitle}>Compartilhamento de Dados</h2> Não
        compartilhamos nenhum dado com terceiros, exceto conforme necessário
        para a utilização da API do WhatsApp Business, em conformidade com os
        Termos e Políticas da Meta.
      </div>{' '}
      <div className={styles.item}>
        <h2 className={styles.h2SubTitle}>Direitos dos Usuários</h2>{' '}
        <span className={styles.DoisPontos}>
          Como usamos os dados apenas para a finalidade descrita, os usuários
          têm o direito de:
        </span>{' '}
        Solicitar a não utilização de seus números de telefone e nomes a
        qualquer momento. Entrar em contato conosco para esclarecer dúvidas
        sobre a privacidade de seus dados.
      </div>
      <div className={styles.contatos}>
        <h2 className={styles.h2SubTitle}>Contato</h2>{' '}
        <span className={styles.DoisPontos}>
          Caso tenha dúvidas ou preocupações relacionadas a esta Política de
          Privacidade, entre em contato conosco:
        </span>
        <p className={styles.contato}> E-mail: suporte@konsist.com.br </p>
        <p className={styles.contato}> Telefone: +55 61 3321-3833 </p>{' '}
        <span className={styles.DoisPontos}>Alterações nesta Política: </span>
        Reservamo-nos o direito de atualizar esta Política de Privacidade
        periodicamente. Todas as alterações serão notificadas com antecedência
        através do nosso aplicativo ou outros meios de comunicação apropriados.
        Ao utilizar nosso aplicativo, você concorda com os termos desta Política
        de Privacidade.
      </div>
    </div>
  );
};

export default PoliticaWhatsApp;
